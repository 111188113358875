import Http from '@/utils/httpClient';

const state = () => ({
    current: {
        attachments: [],
        links: [],
        logs: [],
    },
    selectedConversation: {},
    tickets: [],
    gettingTickets: false,
    meta: {},
    priorities: [],
    departments: [],
    types: [],
    statuses: [],
    descriptionFormats: [],
})

const getters = {
    current: (state) => state.current,
    selectedConversation: (state) => state.selectedConversation,
    tickets: (state) => state.tickets,
    gettingTickets: (state) => state.gettingTickets,
    meta: (state) => state.meta,
    priorities: (state) => state.priorities,
    descriptionFormats: (state) => state.descriptionFormats,
    departments: (state) => state.departments,
    types: (state) => state.types,
    statuses: (state) => state.statuses,
}

const mutations = {
    setCurrent(state, ticket) {
        state.current = ticket;
    },
    setTickets(state, tickets) {
        state.tickets = tickets;
    },
    setMeta(state, meta) {
        state.meta = meta;
    },
    setPriorities(state, priorities) {
        state.priorities = priorities;
    },
    setDepartments(state, departments) {
        state.departments = departments;
    },
    setTypes(state, types) {
        state.types = types;
    },
    setStatuses(state, statuses) {
        state.statuses = statuses;
    },
    setDescriptionFormats(state, formats) {
        state.descriptionFormats = formats;
    },
    setGettingTickets(state, status) {
        state.gettingTickets = status;
    },
    addConversation(state, conversation) {
        state.current.logs.push(conversation);
    },
    selectConversation(state, conversation) {
        state.selectedConversation = conversation;
    },
    updateConversation(state, conversation) {
        const index = state.current.conversations.findIndex((item) => item.id === conversation.id);

        if (index !== -1) {
            state.current.conversations.splice(index, 1, conversation);
        }
    },
    deleteConversation(state, { id, parentId }) {
        if (parentId) {
            const parentIndex = state.current.conversations.findIndex((item) => item.id === parentId)
            const index = state.current.conversations[parentIndex].replies.findIndex((item) => item.id === id);
            state.current.conversations[parentIndex].replies.splice(index, 1);
            return;
        }

        const index = state.current.conversations.findIndex((item) => item.id === id);

        if (index !== -1) {
            state.current.conversations.splice(index, 1);
        }
    },
    storeConversationReply(state, { id, data }) {
        const index = state.current.logs.findIndex((item) => item.id === id);

        if (index !== -1) {
            state.current.logs[index].children.push(data);
        }
    },
    updateCurrentPriority(state, priority) {
        state.current.priority_id = priority;
    },
    updateTicket(state, { id, props }) {
        const index = state.tickets.findIndex((ticket) => ticket.id === id);

        if (index !== -1) {
            const ticket = Object.assign({}, state.tickets[index], props);

            state.tickets.splice(index, 1, ticket);
        }
    },
    updateCurrent(state, props) {
        state.current = Object.assign({}, state.current, props);
    }
}

const actions = {
    softDeleteConversation({ commit }, { id, url, parentId }) {
        return Http.delete(url)
            .then(() => {
                commit('deleteConversation', { id, parentId });
            })
    },
    getTickets({ commit }, params) {
        commit('setGettingTickets', true);

        return Http.get('v1/tickets', {
                params,
            })
            .then(({ data }) => {
                commit('setTickets', data.data);
                commit('setGettingTickets', false);
                commit('setMeta', data.meta);
                commit('setPriorities', data.priorities);
                commit('setDescriptionFormats', data.description_formats);
            })
            .catch(() => {
                commit('setGettingTickets', false);
            })
    },
    updateTicketPriority(context, { ticketId, priorityId }) {
        Http.patch(`tickets/${ticketId}/priority`, {
            ticket_priority_id: priorityId,
        })
    },
    updateDescriptionFormat(context, { ticketId, formatId }) {
        Http.patch(`tickets/${ticketId}/description-format`, {
            description_format: formatId,
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
